.row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.row > input {
    flex-shrink: 3;
    max-width: 6rem;
    text-align: center;
}
