.calc {
    display: block;
    max-width: 35rem;
}

.tabs {
    align-items: center;
    display: flex;
    justify-content: center;
}

.tab {
    all: unset;
    padding: 0.5rem;
}

.tab.active {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom-color: transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.tab.inactive {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

/* Give the focused (not necessarily active!) tab a light background. Helps with
 * keyboard navigation */
.tab:focus {
    background: #eeeeee;
}
